export const routes = {
  main: "/",
  sandbox: "/sandbox",
  auth: {
    root: "/user",
    phone: "/user/phone",
    email: "/user/email",
    password: (id: number | string) => `/user/password/${id}`,
  },
};
