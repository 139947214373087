import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { API } from "../../api";
import { approveApplicationType } from "../../api/underwriter/types";
import { applicationType } from "../../types/Applications/types";
import { sagaApiType } from "../../common_modules/auth/sagas/types";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { processStateController } from "../util/helpers/processStateController";
import { sagaActions } from "../util/sagaActions";
import { cachedPhotos } from "../../store/indexedDB";
import { applicationsReducer, applicationsReducerSelectors } from "../../store/reducers/applications";

/*
  Сага диспачится при нажатии на "одобрить" в калькуляторе
 */
export function* approveApplicationSuperUnderwriterSaga({
  payload,
  type: actionType,
}: PayloadAction<approveApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const application: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    const response: sagaApiType = yield call(
      API.underwriter.sendOffers,
      payload
    );

    if (response.status === 204) {
      const approveResponse: sagaApiType = yield call(API.underwriter.approveApplicationSuperunderwriter, {
        applicationId: payload.applicationId,
        sms_code: payload.sms_code,
      });

      if (approveResponse.status === 204) {
        cachedPhotos.deletePhoto(application?.document_photos.concat(application?.car_photos).map((item) => item.id));
      }
    }

    yield put(sagaActions.underwriter.getApplications());
    yield put(applicationsReducer.actions.setLoadingApprove(true));
    yield put(applicationsReducer.actions.setSaveCurrentApplication(true));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.approveApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
