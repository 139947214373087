import { call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { errorMapper } from "api/methods/errorMapper";
import { getFirstErrorFromResponse } from "../../../api/methods/getFirstErrorFromResponse";
import { logout } from "common_modules/auth/userReducer";
import { sagaActions } from "../sagaActions";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { uiReducer } from "../../../store/reducers/ui";

const getTextFromAxiosErrorMessage = (message: string) => {
  if (message?.includes("Network")) {
    return "Ошибка сети. Проверьте подключение к интернету.";
  }
  if (message?.includes("timeout")) {
    return "Превышено время ожидания. Повторите запрос позднее.";
  }
  return message;
};

export type errorHandlerSagaType = {
  response: any;
  processType?: string;
  handledStatuses?: Array<number>;
};

export function* errorHandlerSaga({
  response: Error,
  processType,
  handledStatuses,
}: errorHandlerSagaType) {
  try {
    console.log("errorHandlerSaga called");
    const response = Error?.response;

    console.log("resp", response);
    console.log(Error.request);
    console.log(Error.message);

    if (processType) {
      yield put(setProcessLoading(processType, false));
    }
    /** No error object case */
    if (!Error) {
      //  TODO API
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: "Неизвестная ошибка",
        })
      );
      return;
    }

    /** Request was canceled by user case */
    if (Error.message === "canceled") {
      return;
    }

    /** No response case */
    if (!response) {
      // TODO API
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message:
            getTextFromAxiosErrorMessage(Error?.message) ||
            "Неизвестная ошибка",
        })
      );
      return;
    }

    //TODO: отдельные кейсы для некоторых запросов, подумать, как сделать это более правильно
    if (response.status === 400 && response.data.data.errors[0].message.includes("offers")) {
      yield put(errorsReducer.actions.setOffersErrors(response.data.data.errors));
      return;
    }

    if (response.status === 404 && response.data.data.errors[0].message.includes("Рекламодатель не найден")) {
      return;
    }

      // Mapping logic
    /* trying map errors to fields */
    const firstError = getFirstErrorFromResponse(response);
    if (firstError) {
      console.log(firstError);
      yield put(errorsReducer.actions.setGlobalError(firstError));

      if (response.status === 400 || response.status === 401) {
        yield put(errorsReducer.actions.setFieldsErrors(errorMapper(response)));
      }
      if (response.status === 401) {
        // TODO протестить, мб не робит
        yield call(logout.saga);
        return;
      }
    }

    /* handle 5XX errors */
    if (response.status >= 500) {
      // TODO API
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: "Временная 500 ошибка сервера",
        })
      );
      return;
    }

    if (response.status >= 404) {
      // TODO API
      const firstError = getFirstErrorFromResponse(response);
      if (firstError) {
        yield put(
          uiReducer.actions.setModal({
            status: "error",
            message: firstError,
          })
        );
      } else {
        yield put(
          uiReducer.actions.setModal({
            status: "error",
            message: "Временная 404 ошибка сервера",
          })
        );
      }
      return;
    }

    /* if status was not handled by saga, set global modal */
    if (!handledStatuses?.includes(response.status)) {
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: firstError || "Ошибка.",
        })
      );
    }
  } catch (e: any) {
    /* handle error handling */
    yield put(
      uiReducer.actions.setModal({
        status: "error",
        message: "Unhandled error.",
      })
    );
  }
}

function* errorHandlerReduxWrapper({
  payload,
}: PayloadAction<errorHandlerSagaType>) {
  yield errorHandlerSaga(payload);
}

export function* errorWatcherSaga() {
  yield takeEvery(sagaActions.error, errorHandlerReduxWrapper);
}
