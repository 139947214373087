/**
 * @payload
 * -- password
 * -- password_confirm
 * -- code
 */
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { sagaActions } from "../util/sagaActions";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { updateApplicationType } from "../../api/underwriter/types";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import {processStateController} from "../util/helpers/processStateController";

export function* updateApplicationPatchSaga({
  payload,
                                              type: actionType,
}: PayloadAction<updateApplicationType>) {
  const process = processStateController(actionType);
  try {
    yield process.start();
    const response: sagaApiType = yield call(
      API.underwriter.updateApplicationPatch,
      payload
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.updateApplicationPatch.type,
    });
  } finally {
    yield process.stop();
  }
}
