import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { processStateController } from "../util/helpers/processStateController";
import { sagaActions } from "../util/sagaActions";
import { applicationsReducer, applicationsReducerSelectors } from "../../store/reducers/applications";
import { approveApplicationType } from "../../api/underwriter/types";
import { sagaApiType } from "../../common_modules/auth/sagas/types";
import { API } from "../../api";
import { checkSwitcherIdReducer } from "../../store/reducers/checkSwitcherId";
import { applicationType } from "../../types/Applications/types";

/*
  Сага диспачится при нажатии на "одобрить" в калькуляторе
 */
export function* approveApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<approveApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const currentApplication: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    yield call(API.underwriter.updateApplicationPatch, {
      applicationId: payload.applicationId,
      actual_page: 5,
    });

    yield put(
      checkSwitcherIdReducer.actions.setCurrentAvailableTabForIds("scoring")
    );

    const response: sagaApiType = yield call(
      API.underwriter.sendOffers,
      payload
    );

    // if (response.status === 204) {
    //   yield call(API.underwriter.approveApplication, {
    //     applicationId: payload.applicationId,
    //     sms_code: payload.sms_code,
    //   });
    // }

    // вкорячил костыльный пут ниже для обновление статуса текущей заявки на несуществующий тупо чтоб она не сетилась
    // ни в одну из трех очередей после аппрува андером, по хорошем надо бы убрать ее из стора на этом этапе, но не
    // получается, т.к. дальше идет еще логика, где юзается эта заявка, возможно, и не очень нужная логика,
    // но скорее всего сломается где-то что-то неочевидное, надо подумать как сделать все это нормально, но быстро на
    // ходу не получается, пока так

    yield put(
      applicationsReducer.actions.setCurrentApplication({
        ...currentApplication,
        application_type: "approved_by_underwriter",
      })
    );

    /*
      Нужно обновлять очередь заявок в сайдбаре, поэтому кидаем пинг
     */
    yield put(sagaActions.underwriter.getApplications());

    yield put(applicationsReducer.actions.setLoadingApprove(true));

    yield put(applicationsReducer.actions.setSaveCurrentApplication(true));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.approveApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
