import axios, { AxiosResponse } from "axios";
import { mainAxios } from "../index";
import {
  daDataBaseResponseType,
  // getAddressType,
  // getCarType,
  // getCompanyType,
  // getInTransactionType,
  // getLoanType,
  // getOutTransactionType,
  getScheduleType,
} from "./types";
import { getDivisionCodeType } from "../application/types";
import { dataToQueryString } from "../../methods/dataToQueryString";

export const servicesApi = {
  getAddress: ({ address }: { address: string }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/address?search=${address}`),

  getBank: ({ bic }: { bic: `${number}` }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/bank?search=${bic}`),

  getCompany: ({ inn }: { inn: `${number}` }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/company?search=${inn}`),

  getPassportIssuedBy: ({ code }: { code: `${number}` }) =>
    mainAxios.get(`/division_code/${code}`),

  getSchedule: (query: getScheduleType): Promise<AxiosResponse<any>> =>
    mainAxios.get(`/schedule${dataToQueryString(query)}`),

  // getLoan: (query: getLoanType) =>
  //   mainAxios.get(`/loan${dataToQueryString(query)}`),
  //
  // getOutTransaction: (query: getOutTransactionType) =>
  //   mainAxios.get(`/out-transaction${dataToQueryString(query)}`),
  //
  // getInTransaction: (query: getInTransactionType) =>
  //   mainAxios.get(`/in-transaction${dataToQueryString(query)}`),

  // getCar: (query: getCarType) =>
  //   mainAxios.get(`/car${dataToQueryString(query)}`),
};
