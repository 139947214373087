import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { authSagaActions } from "../actions";
import { processStateController } from "../../helpers";
import { getUserType, sagaApiType } from "../types";
import { UserType } from "../types";
// userReducer вынесен в сабмодуль и коннектится в сторе проекта через импорт в файл где combineReducers
import { userReducer } from "common_modules/auth/userReducer";
import { redirect } from "../../helpers";
import { authApi } from "../../api";
import { authRoutes } from "../../authRoutes";
import { errorsReducer } from "../../../../store/auxiliary/errorsReducer";

export function* getUserSaga({
  payload,
  type: actionType,
}: PayloadAction<getUserType>) {
  const process = processStateController(actionType);
  try {
    yield process.start();
    const response: sagaApiType = yield call(authApi.get, payload);

    const users = response?.data?.data;

    if (!users.length) {
      /*
        Кейс для андера и админа.
        Если юзера нет в беке, даем ошибку
       */
      if (payload?.requiredUserRole) {
        yield put(
          errorsReducer.actions.setFieldsErrors({
            phone_number: "Пользователь с указанным номером не существует",
          })
        );
        return;
      }

      /**
       * Если пришел пустой массив,
       * то пользователя нет.
       * Eго нужно создать
       * */
      yield put(
        authSagaActions.createUser({
          phone: payload.phone,
        })
      );
      return;
    }

    const { id, is_has_password, signed_pdn }: UserType = users[0];

    yield put(
      userReducer.actions.setPhone({
        phone: payload.phone,
      })
    );

    yield put(
      userReducer.actions.setId({
        user_id: id,
      })
    );

    yield redirect(authRoutes.password);

    yield process.stop();
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: authSagaActions.getUser.type,
    });
  } finally {
    yield process.stop();
  }
}
