import React from "react";
import { Route } from "react-router-dom";
import "./assets/styles/index.css";
// import { Sandbox } from "./components/pages/Sandbox";
import { routes } from "./routes";
import { Router } from "./routes/RouterDevTools";
import { createArrayRouter } from "./routes/createRouter";
import { lazyLoad } from "./helpers/lazyLoad";
import { LoaderPage } from "./components/ui/LoaderPage";
import { useSelector } from "react-redux";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { processLoading } from "./store/auxiliary/loadingReducer";
import { ModalWindow } from "./components/ui/ModalWindow";
import { Auth } from "./components/pages/Auth";
import { authRoutes } from "./common_modules/auth/authRoutes";

const Sandbox = lazyLoad(() => import("./components/pages/Sandbox"));
// const Main = lazyLoad(() => import("./components/pages/Main"));
const MainPage = lazyLoad(() => import("./components/pages/MainPage"));

function App() {
  const autoAuthProcess = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  if (autoAuthProcess) {
    return <LoaderPage />;
  }

  return (
    <>
      <Route path={authRoutes.root}>
        <Auth />
      </Route>

      <Route path={routes.main} exact>
        <MainPage />
      </Route>
      <ModalWindow />

      {process.env.NODE_ENV === "development" && (
        <>
          <Router router={createArrayRouter(routes)} />
          <Route path={routes.sandbox}>
            <Sandbox />
          </Route>
        </>
      )}
    </>
  );
}

export default App;
