// import { authApi } from "./Auth";
import { mainApi } from "./main";
import { underwriterApi } from "./underwriter";
import { authApi } from "../common_modules/auth/api";

export const API = {
  Auth: authApi,
  main: mainApi,
  underwriter: underwriterApi,
};
