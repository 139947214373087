export type ErrorType = {
  [key: string]: string;
};
export const errorMapper = (response: any) => {
  const errorsFromServer = response?.data?.data?.errors;
  if (!Array.isArray(errorsFromServer)) return {};

  let errorsObject: ErrorType = {};
  errorsFromServer.forEach((item: ErrorType) => {
    if (!item?.field) {
      return;
    }
    const indexOfDots = item.message.indexOf(":");
    const fieldName = item.field.substr(item.field.lastIndexOf(".") + 1);
    errorsObject[fieldName] = indexOfDots
      ? item.message.substr(indexOfDots + 2)
      : item.message;
  });

  for (const field in errorsObject) {
    if (errorsObject[field] === "Неправильное контрольное число") {
      errorsObject[field] = "Проверка на ключевание не пройдена";
    }
  }

  return errorsObject;
};
