import Dexie, { Table } from "dexie";
import { DocumentApiTypes } from "../../types/documents/types";

export type cachePhotosType = {
  photo_id: `${number}`;
  application_id: number;
  photo_type: DocumentApiTypes.documentType;
  content: string;
  file_name: string;
};

class CachedPhotos extends Dexie {
  photos!: Table<cachePhotosType>;

  constructor() {
    super("photos");
    this.version(1).stores({
      photos: "photo_id"
    })
  };

  async deletePhoto(photoIds: number[] | undefined) {
    if (photoIds) {
      for (const photoId of photoIds) {
        const cachedPhoto = await cachedPhotos.photos.get({ photo_id: photoId });

        if (cachedPhoto) {
          await cachedPhotos.photos.delete(photoId);
        }
      }
    }
  };
}

export const cachedPhotos = new CachedPhotos();
