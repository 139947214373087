import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { processLoading, setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { sagaActions } from "../util/sagaActions";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import {
  applicationsReducer,
  applicationsReducerSelectors,
} from "../../store/reducers/applications";
import { useSelector } from "react-redux";
import { StateType } from "../../store";
import { applicationType } from "../../types/Applications/types";
import { uiReducer } from "../../store/reducers/ui";
import { checkSwitcherIdReducer } from "../../store/reducers/checkSwitcherId";
import React from "react";
import { tabValuesType } from "../../components/pages/MainPage/ThisApplication/Tabs/types";
import { stringToNumber } from "../../helpers/string";

export type sendPatchFromCreditworthinessSagaType = {
  data: {
    bki: string;
    current_post_due_amount: number | null | undefined;
    ssp: number | null | undefined;
    credit_rating: number;
    psk: string | undefined;
    uuid: string | undefined;
    found_in_credit_history: boolean | undefined;
  };
  // setActiveSection: React.Dispatch<React.SetStateAction<tabValuesType>>;
  isApplicationToDecline: boolean;
};

/*
  Для сабмита со скоринга на видеозвонок решил написать сагу,
  потому что 2 ифа (патч либо отказ) + кроме патча летит еще put /video-call
 */
export function* sendPatchFromCreditworthinessSaga({
  payload,
}: PayloadAction<sendPatchFromCreditworthinessSagaType>) {
  try {
    yield put(
      setProcessLoading(
        sagaActions.underwriter.sendPatchFromCreditworthinessSaga.type,
        true
      )
    );
    console.log("sendPatchFromCreditworthinessSaga called", payload);

    const applications: applicationType[] = yield select(
      applicationsReducerSelectors.getApplications
    );

    const currentApplication: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );
    const applicationId = currentApplication.id;
    if (!applicationId) return;

    if (payload.isApplicationToDecline) {
      yield put(
        sagaActions.underwriter.declineApplication({
          applicationId: Number(applicationId),
          refuse_reason: "underwriter_4_bki",
          refuse_switch: "Превышен ПДН",
          refuse_underwriter_comment: payload.data.bki,
        })
      );
      return;
    }

    // patch `/application/${applicationId}`
    const patchResponse: sagaApiType = yield call(
      API.underwriter.updateApplicationPatch,
      {
        applicationId: applicationId,
        /*
         возможно actual_page: 6 тут не нужен,
         тк мы понимаем что заявка в видеозвонке по
         status: video_call. Только табы и остальное уже написано под actual_page
         */
        actual_page: 4,
        // solvency: payload.data
        //   ? {
        //     current_post_due_amount: stringToNumber(
        //       payload.data.current_post_due_amount
        //     ),
        //     ssp: stringToNumber(payload.data.ssp),
        //     credit_rating: stringToNumber(payload.data.credit_rating)
        //   }
        //   : undefined,
        solvency: {
          // ssp: stringToNumber(payload.data.ssp),
          credit_rating: stringToNumber(payload.data.credit_rating)
        },
        ...(currentApplication.repledge && {
          repledge_external_loan_data: {
            found_in_credit_history: payload.data.found_in_credit_history,
            ...(payload.data.found_in_credit_history && { psk: payload.data.psk }),
            ...(payload.data.found_in_credit_history && { uuid: payload.data.uuid }),
          }
        }),
        check_block_comments: payload.data.bki
          ? {
            bki: payload.data.bki,
          }
          : undefined,
      }
    );

    if (patchResponse.status !== 200) return;

    // сразу сетим обновленную заявку в стор и дальше юзаем уже currentApplicationUpdated
    yield put(
      applicationsReducer.actions.setCurrentApplication(
        patchResponse.data.data
      )
    );

    const currentApplicationUpdated: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    // put `/application/${applicationId}/video-call`
    const putResponse: sagaApiType = yield call(
      API.underwriter.putApplicationToVideoCallStatus,
      { applicationId }
    );

    // payload.setActiveSection("videoCall");
    yield put(
      checkSwitcherIdReducer.actions.setCurrentAvailableTabForIds("videoCall")
    );

    //  тут заявки не сортируем по времени, а подтыкаем текущую со статусом видеозвонка в начало
    if (applications?.length) {
      yield put(
        applicationsReducer.actions.setApplications({
          applications: [
            { ...patchResponse.data.data, application_type: "video_call" },
            ...applications.filter(
              (item) => item.id !== currentApplicationUpdated?.id
            ),
          ],
        })
      );
    }
    yield put(
      applicationsReducer.actions.setCurrentApplication({
        ...patchResponse.data.data,
        status: "video_call",
        // actual_page: 5,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.updateApplication.type,
    });
  } finally {
    yield put(
      setProcessLoading(
        sagaActions.underwriter.sendPatchFromCreditworthinessSaga.type,
        false
      )
    );
  }
}
