import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { processStateController } from "./processStateController";
import { sagaActions } from "../sagaActions";
import { errorHandlerSaga } from "./errorHandlerSaga";
import { applicationsReducer } from "../../../store/reducers/applications";
import { checkSwitcherIdReducer } from "../../../store/reducers/checkSwitcherId";
import { documentsReducer } from "../../../store/reducers/documents";
import { VerificationSwitchDisabledReducer } from "../../../store/reducers/VerificationSwitchDisabled";

export function* clearRedusersSaga({ type: actionType }: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    yield put(applicationsReducer.actions.clear());
    yield put(checkSwitcherIdReducer.actions.clear());
    yield put(documentsReducer.actions.clear());
    yield put(VerificationSwitchDisabledReducer.actions.clear());
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.clearRedusersSaga.type,
    });
  } finally {
    yield process.stop();
  }
}
