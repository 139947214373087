import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { processStateController } from "../util/helpers/processStateController";
import { sagaActions } from "../util/sagaActions";
import { API } from "../../api";
import { authRoutes } from "../../common_modules/auth/authRoutes";

export function* LogoutSaga({
  type: actionType,
}: PayloadAction<any>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    yield call(API.underwriter.logout);

    yield put(push(authRoutes.phone));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.logout.type,
    });
  } finally {
    yield process.stop();
  }
}
