import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { ReactComponent as LoaderIcon } from "../../../common_modules/assets/loader.svg";

type propsType = {
  variant?: "large";
  color?: "white" | "purple";
} & HTMLAttributes<HTMLOrSVGElement>;
export const Loader: FC<propsType> = (props) => {
  return (
    <LoaderIcon
      {...props}
      className={cn(styles.container, props.className, {
        [styles[`container--${props.variant}`]]: props.variant,
        [styles[`color--${props.color}`]]: props.color,
      })}
    />
  );
};
