import { PayloadAction } from "@reduxjs/toolkit";
import { createPasswordType } from "common_modules/auth/sagas/types";
import { processStateController } from "sagas/util/helpers/processStateController";
import { sagaApiType } from "common_modules/auth/sagas/types";
import { call, put, select } from "redux-saga/effects";
import { authSagaActions } from "../actions";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { userReducer } from "common_modules/auth/userReducer";
import { userReducerSelectors } from "common_modules/auth/selectors";
import { routes } from "routes";
import { redirect } from "sagas/util/helpers/redirect";
import { authApi } from "../../api";

export function* createPasswordSaga({
  payload,
  type: actionType,
}: PayloadAction<createPasswordType>) {
  const process = processStateController(actionType);

  try {
    const code: string = yield select(userReducerSelectors.getCode);

    yield process.start();
    const response: sagaApiType = yield call(authApi.createPassword, {
      ...payload,
      code,
    });

    const { token } = response.data.data;

    yield put(userReducer.actions.setToken({ token }));

    yield redirect(routes.main);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
      handledStatuses: [400],
      processType: authSagaActions.createPassword.type,
    });
  } finally {
    yield process.stop();
  }
}