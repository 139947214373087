import { TextInputPropsType } from "../types";
import { useToggle } from "../../../../hooks/logical/useToggle";
import { useRef } from "react";
import { ConnectedForm } from "../../ConnectedForm";
import {ReactComponent as Eye} from "../../../../common_modules/assets/eye.svg"
import {ReactComponent as ClosedEye} from "../../../../common_modules/assets/close_eye.svg"
import cn from "classnames";
import styles from "../index.module.css";
import get from "lodash/get";
import { InputBase } from "../InputBase";
import mergeRefs from "react-merge-refs";

export const InputPassword = (props: Omit<TextInputPropsType, "control">) => {
  const { className, ...other } = props;
  const [isVisible, toggleVisible] = useToggle(false);
  const ref = useRef<any>();

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <InputBase
          {...other}
          type={isVisible ? "text" : "password"}
          inputElementRef={mergeRefs([ref, props.inputElementRef])}
          support={
            <div onClick={toggleVisible}>
              {isVisible ? <Eye /> : <ClosedEye />}
            </div>
          }
          className={cn({
            [`${props.className}`]: true,
            [styles.password]: !isVisible,
          })}
          // error={props.error || get(errors, `${props.name}.message`)}
          control={control}
        />
      )}
    </ConnectedForm>
  );
};
