import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { Button } from "../../components/Button";
import { CodeField } from "components/ui/CodeField";
import { useCodeFieldLogic } from "components/ui/CodeField/useCodeFieldLogic";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { userReducerSelectors } from "common_modules/auth/selectors";
import { CheckboxUncontrolled } from "components/ui/Checkbox";
import { routes } from "../../../../../routes";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authRoutes } from "../../../../../common_modules/auth/authRoutes";

export const Code: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const phone = useSelector(userReducerSelectors.getPhone);
  const errors = useSelector(errorsSelectors.fieldsErrors);
  const loading = useSelector(
    processLoading.get(authSagaActions.verifyCode.type)
  );
  const [isChecked1, setIsChecked1] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);

  const { codeMode } = useParams<{ codeMode: "restore" | "register" }>();

  const codeField = useCodeFieldLogic({
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const confirm = () => {
    dispatch(
      authSagaActions.verifyCode({
        code: codeField.value,
      })
    );
  };

  const resend = () => {
    dispatch(authSagaActions.getCode({ smsTemplate: codeMode }));
  };

  const isButtonDisabled =
    pathname === `${authRoutes.code}/register`
      ? codeField.value.length !== 4 || !(isChecked1 && isChecked2)
      : codeField.value.length !== 4;

  return (
    <div className={styles.container}>
      <CodeField
        {...codeField}
        sendCodeHandler={resend}
        error={errors?.code}
        className={styles.codeField}
        retryCount={codeField.retryCount}
        setRetryCount={codeField.setRetryCount}
      />

      <Button
        loading={loading}
        disabled={isButtonDisabled}
        onClick={confirm}
        className={styles.button}
      >
        Продолжить
      </Button>

      {/* У нас нет регистрации андера */}
      {/*{pathname === `${authRoutes.code}/register` ? (*/}
      {/*  <div className={styles.pers_data}>*/}
      {/*    <CheckboxUncontrolled*/}
      {/*      name={"pd"}*/}
      {/*      blockValue={true}*/}
      {/*      text={*/}
      {/*        <>*/}
      {/*          /!*<a> Согласен на обработку</a> персональных данных*!/*/}
      {/*          Согласен на обработку персональных данных*/}
      {/*        </>*/}
      {/*      }*/}
      {/*      onChange={() => setIsChecked1((prev) => !prev)}*/}
      {/*      checked={isChecked1}*/}
      {/*    />*/}

      {/*    <CheckboxUncontrolled*/}
      {/*      name={"booba"}*/}
      {/*      text={*/}
      {/*        <>*/}
      {/*          Соглашаюсь с правилами аналога <br /> собственноручной подписи*/}
      {/*        </>*/}
      {/*      }*/}
      {/*      onChange={() => setIsChecked2((prev) => !prev)}*/}
      {/*      checked={isChecked2}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </div>
  );
};