import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "store/index";

export type OfferErrorType = {
  code: string;
  message: string;
  field: string;
};

export const errorsReducer = createSlice({
  name: "errorsReducer",
  initialState: {
    fieldsErrors: {},
    globalError: "",
    offersErrors: [],
  } as {
    fieldsErrors: {
      [key: string]: string;
    };
    globalError: string;
    offersErrors: OfferErrorType[];
  },
  reducers: {
    setFieldsErrors: (state, { payload }: PayloadAction<any>) => {
      state.fieldsErrors = payload;
    },
    setGlobalError: (state, { payload }: PayloadAction<string>) => {
      state.globalError = payload;
    },
    setOffersErrors: (state, { payload }: PayloadAction<OfferErrorType[]>) => {
      state.offersErrors = payload;
    },
    removeFieldError: (state, { payload }: PayloadAction<string>) => {
      const oldErrors = { ...state.fieldsErrors };
      delete oldErrors[payload];
      state.fieldsErrors = oldErrors;
    },
    clearOfferError: (state, { payload }: PayloadAction<number>) => {
      state.offersErrors = state.offersErrors.filter((offer) => {
        const dotIdx = offer.field.indexOf(".")
        const fieldNumber = Number(offer.field.slice(dotIdx + 1, dotIdx + 2))
        return fieldNumber !== payload
    });
    },
  },
});

export const errorsSelectors = {
  fieldsErrors: (state: StateType) => state.errorsReducer.fieldsErrors,
  globalError: (state: StateType) => state.errorsReducer.globalError,
  offersErrors: (state: StateType) => state.errorsReducer.offersErrors,
};
