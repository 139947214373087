import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { getUserType } from "../../api/underwriter/types";
import { processStateController } from "../util/helpers/processStateController";
import { UserTypeNew } from "../../common_modules/auth/sagas/types";
import { UserDataReducer } from "../../store/reducers/userData";

export function* getUserSaga({ type: actionType }: PayloadAction<getUserType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(API.Auth.getUserTokenized);
    const data: UserTypeNew = response.data.data;

    yield put(UserDataReducer.actions.setPhone(data.phone_number));
    yield put(UserDataReducer.actions.setName(data.name));
    yield put(UserDataReducer.actions.setPatronymic(data.patronymic));
    yield put(UserDataReducer.actions.setSurname(data.surname));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.getUserSaga.type,
    });
  } finally {
    yield process.stop();
  }
}
