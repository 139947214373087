import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { API } from "../../api";
import { getUserType } from "../../api/underwriter/types";
import { sagaApiType } from "../util/types";
import { sagaActions } from "../util/sagaActions";
import { processStateController } from "../util/helpers/processStateController";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { applicationsReducer } from "../../store/reducers/applications";

export let abortController = new AbortController();

export function* getCurrentApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<getUserType>) {
  const process = processStateController(actionType);

  // Токен нужен чтобы не пинговать неподписанным запросом в бек после разлогина

  try {
    yield process.start();

    abortController = new AbortController();

    const application: sagaApiType = yield call(
      API.underwriter.getApplication, {
        applicationId: payload.applicationId,
        abortSignal: abortController.signal
      }
    );
    yield put(
      applicationsReducer.actions.setCurrentApplication({
        ...application.data.data,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.getApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
