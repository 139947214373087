import { call, delay, put } from "redux-saga/effects";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { lsController } from "store/localStorage";
import { userReducer } from "common_modules/auth/userReducer";
import { routes } from "../../../routes";
import { redirect } from "../helpers";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { authRoutes } from "../authRoutes";
import { authSagaActions } from "common_modules/auth/sagas/actions";
// TODO подумать куда деть этот импорт для другого приложения. пока так
// мб автоаус перенести в само приложение обратно
import { sagaActions } from "../../../sagas/util/sagaActions";

export function* autoAuthSaga() {
  const actionType = authSagaActions.autoAuth.type;
  try {
    yield put(setProcessLoading(actionType, true));

    const token = lsController.get("token");
    const user_id = lsController.get("user_id");
    const phone = lsController.get("phone");


    // TODO выпилить или изменить время
    yield delay(1000);
    if (token && user_id && phone) {
      yield put(userReducer.actions.setToken({ token }));

      yield put(userReducer.actions.setId({ user_id }));

      yield put(userReducer.actions.setPhone({ phone }));

      yield put(setProcessLoading(actionType, false));


      if (process.env.REACT_APP_USER_ROLE === "underwriter") {
        yield put(sagaActions.underwriter.pingApplications({}));
      }

      yield redirect(routes.main);
    } else {
      yield redirect(authRoutes.phone);

      yield put(setProcessLoading(actionType, false));
    }
  } catch (Error) {
    yield call(errorHandlerSaga, {
      response: Error,
    });
  }
}
