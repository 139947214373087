import React, { FC } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { SmartForm } from "components/hocs/SmartForm";
import { InputPassword } from "components/ui/Inputs/InputPassword";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { userReducerSelectors } from "common_modules/auth/selectors";
import { processLoading } from "store/auxiliary/loadingReducer";
import { Button } from "../../components/Button";

type formType = {
  password: string;
  password_confirm: string;
};

export const RestorePassword: FC = () => {
  const dispatch = useDispatch();
  const code = useSelector(userReducerSelectors.getCode);
  const loading = useSelector(
    processLoading.get(authSagaActions.createPassword.type)
  );
  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const [password, password_confirm] = useWatch({
    control: form.control,
    name: ["password", "password_confirm"],
  });

  const submit = (data: formType) => {
    dispatch(
      authSagaActions.createPassword({
        ...data,
        code: code as string,
      })
    );
  };

  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit}>
        <InputPassword
          name={"password"}
          label="Пароль"
          autoFocus
          className={styles.password}
        />
        <InputPassword
          name={"password_confirm"}
          label="Повторите пароль"
          className={styles.password}
        />

        <Button
          loading={loading}
          className={styles.button}
        >
          Продолжить
        </Button>
      </SmartForm>
    </div>
  );
};
