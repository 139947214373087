/**
 * @payload
 * -- password
 * -- password_confirm
 * -- code
 */
import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { getDocumentType } from "../../api/underwriter/types";
import { processStateController } from "../util/helpers/processStateController";
import { downloadFile } from "../../helpers/file";
import { documentToDownloadType } from "../../types/documents/types";

const documentTypeDescriptionMap: { [key in documentToDownloadType]?: string } =
  {
    check_fio_report: "Проверка клиента",
    check_phone_report: "Проверка телефона",
    check_vehicle_report: "Полный отчет по автомобилю",
  };

export function* downloadFileSaga({
  payload,
  type: actionType,
}: PayloadAction<getDocumentType>) {
  const process = processStateController(`${actionType}/${payload.document_type}`);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.underwriter.downloadFile,
      payload
    );

    let nameFileDownload =
      documentTypeDescriptionMap[
        payload.document_type as documentToDownloadType
      ] || payload.document_type;

    const url = response?.data?.data?.content;
    downloadFile(url, `${nameFileDownload}`, "_blank");
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.downloadFile.type,
    });
  } finally {
    yield process.stop();
  }
}
