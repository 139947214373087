import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentApiTypes } from "../../../types/documents/types";

const initialState: Record<DocumentApiTypes.documentType, any> = {
  passport_main: null,
  passport_registration: null,
  driver_license_front: null,
  driver_license_back: null,
  selfie_with_passport: null,
  vehicle_registration_certificate_front: null,
  vehicle_registration_certificate_back: null,
  pts_front: null,
  pts_back: null,

  front_right: null,
  front_left: null,
  back_right: null,
  back_left: null,
  dashboard: null,
  vin: null,
  selfie_with_car: null,
  interior: null,

  repledge_certificate_of_debt_status: null,
  repledge_loan_contract: []
};

export const documentsReducer = createSlice({
  name: "@documentsReducer",
  initialState,
  reducers: {
    setPhotoInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        photo_type: DocumentApiTypes.documentType;
        data: any;
      }>
    ) => {
      state[payload.photo_type] = payload.data;
    },
    setRepledgeLoanContractPhotoInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        photo_type: DocumentApiTypes.documentType;
        data: any;
        id?: `${number}`;
      }>
    ) => {
      state["repledge_loan_contract"].push({ ...payload.data, id: payload.id });
    },
    clear: () => initialState,
  },
});
