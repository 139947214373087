import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../../index";
import { tabValuesType } from "../../../components/pages/MainPage/ThisApplication/Tabs/types";

type initialStateType = {
  id: number;
  declineId: number | null;
  currentTabForIds: tabValuesType
};



const initialState: initialStateType = {
  id: -1,
  declineId: null,
  currentTabForIds: "generalInfo",
};

export const checkSwitcherIdReducer = createSlice({
  name: "@checkSwitcherIdReducer",
  initialState,
  reducers: {
    setId: (state, { payload }: PayloadAction<any>) => {
      state.id = payload;
    },
    setDeclineId: (state, { payload }: PayloadAction<any>) => {
      state.declineId = payload;
    },
    setCurrentAvailableTabForIds: (state, { payload }: PayloadAction<tabValuesType>) => {
      state.currentTabForIds = payload;
    },
    clear: () => initialState,
  },
});
export const checkSwitcherIdReducerSelectors = {
  getId: (state: StateType) => state.checkSwitcherIdReducer.id,
  getDeclineId: (state: StateType) => state.checkSwitcherIdReducer.declineId,
  getCurrentTabForIds: (state: StateType) => state.checkSwitcherIdReducer.currentTabForIds,
};
