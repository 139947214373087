import { createAction } from "@reduxjs/toolkit";
import {
  getCodeType,
  verifyCodeType,
  createPasswordType,
  getTokenType, getUserType
} from "./types";

export const authSagaActions = {
  getUser: createAction<getUserType>("Auth/getUser"),
  createUser: createAction<{ phone: string }>("Auth/createUser"),
  getCode: createAction<getCodeType>("Auth/getCode"),
  verifyCode: createAction<verifyCodeType>("Auth/verifyCode"),
  createPassword: createAction<createPasswordType>("Auth/createPassword"),
  getToken: createAction<getTokenType>("Auth/getToken"),
  autoAuth: createAction<{ password: string }>("Auth/autoAuth"),
  logoutSaga: createAction("Auth/logout"),
};
