import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { sagaActions } from "../util/sagaActions";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import {
  applicationsReducer,
  applicationsReducerSelectors,
} from "../../store/reducers/applications";
import { useSelector } from "react-redux";
import { StateType } from "../../store";
import { applicationType } from "../../types/Applications/types";
import { uiReducer } from "../../store/reducers/ui";
import { checkSwitcherIdReducer } from "../../store/reducers/checkSwitcherId";
import React from "react";
import { tabValuesType } from "../../components/pages/MainPage/ThisApplication/Tabs/types";

export type sendPatchFromScoringPayloadType = {
  data: {
    pdn: string;
  };
  setActiveSection: React.Dispatch<React.SetStateAction<tabValuesType>>;
  isApplicationToDecline: boolean;
};

/*
  Для сабмита со скоринга на видеозвонок решил написать сагу,
  потому что 2 ифа (патч либо отказ) + кроме патча летит еще put /video-call
 */
export function* sendPatchFromScoringSaga({
  payload,
}: PayloadAction<sendPatchFromScoringPayloadType>) {
  try {
    put(
      setProcessLoading(
        sagaActions.underwriter.sendPatchFromScoringSaga.type,
        true
      )
    );
    console.log("sendPatchFromScoringSaga called");

    const applications: applicationType[] = yield select(
      applicationsReducerSelectors.getApplications
    );

    const currentApplication: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );
    const applicationId = currentApplication.id;
    if (!applicationId) return;

    if (payload.isApplicationToDecline) {
      console.log(payload);
      // yield put(
      //   sagaActions.underwriter.declineApplication({
      //     applicationId: Number(applicationId),
      //     refuse_reason: "pdn",
      //     refusal_check_block:
      //       "Превышен ПДН",
      //     refuse_underwriter_comment: payload.data.pdn,
      //   })
      // );
      return;
    }

    // patch `/application/${applicationId}`
    const patchResponse: sagaApiType = yield call(
      API.underwriter.updateApplicationPatch,
      {
        applicationId: applicationId,
        /*
         возможно actual_page: 6 тут не нужен,
         тк мы понимаем что заявка в видеозвонке по
         status: video_call. Только табы и остальное уже написано под actual_page
         */
        actual_page: 5,
        check_block_comments: payload.data.pdn
          ? {
              pdn: payload.data.pdn,
            }
          : undefined,
      }
    );

    if (patchResponse.status !== 200) return;

    // put `/application/${applicationId}/video-call`
    const putResponse: sagaApiType = yield call(
      API.underwriter.putApplicationToVideoCallStatus,
      { applicationId }
    );

    payload.setActiveSection("videoCall");
    yield put(
      checkSwitcherIdReducer.actions.setCurrentAvailableTabForIds("videoCall")
    );

    //  тут заявки не сортируем по времени, а подтыкаем текущую со статусом видеозвонка в начало
    if (applications?.length) {
      yield put(
        applicationsReducer.actions.setApplications({
          applications: [
            { ...currentApplication, application_type: "video_call" },
            ...applications.filter(
              (item) => item.id !== currentApplication?.id
            ),
          ],
        })
      );
    }
    yield put(
      applicationsReducer.actions.setCurrentApplication({
        ...currentApplication,
        status: "video_call",
        actual_page: 5,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.updateApplication.type,
    });
  } finally {
    yield put(
      setProcessLoading(
        sagaActions.underwriter.sendPatchFromScoringSaga.type,
        false
      )
    );
  }
}
