//
// const application: any = yield call(
//   API.underwriter.getApplication
// );
// yield put(
//   applicationsReducer.actions.setSaveCurrentApplication(application.data)
// );

import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../util/helpers/processStateController";
import { call } from "redux-saga/effects";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { uploadApplicationDocumentType } from "../../api/underwriter/types";

export function* uploadApplicationDocumentSaga({
  payload,
  type: actionType,
}: PayloadAction<uploadApplicationDocumentType>) {
  const process = processStateController(actionType);

  // Токен нужен чтобы не пинговать неподписанным запросом в бек после разлогина

  try {
    yield process.start();
    const application: sagaApiType = yield call(
      API.underwriter.uploadApplicationDocument,
      payload
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.uploadApplicationDocumentSaga.type,
    });
  } finally {
    yield process.stop();
  }
}
