import { authAxios } from "../index";
// import { mainAxios } from "../main";
// import { underwriterAxios } from "../underwriter";
import { lsController } from "store/auxiliary/localStorage";
import { mainAxios } from "../../../../api/main";
import { underwriterAxios } from "../../../../api/underwriter";

export const setTokenToAxios = (token?: string | null) => {
  const transformedToken = token ? "Bearer " + token : "";
  authAxios.defaults.headers.common["Authorization"] = transformedToken;
  mainAxios.defaults.headers.common["Authorization"] = transformedToken;
  underwriterAxios.defaults.headers.common["Authorization"] = transformedToken;

  lsController.set("token", token || "");
};
