import styles from "./index.module.css";
import React, { HTMLAttributes } from "react";
import cn from "classnames";
import { Icons } from "assets/icons";

type propsType = {
  margin?: "small" | "medium";
  form?: string;
  disabled?: boolean;
  size?: "small" | "big";
  variant?:
    | "default"
    | "underline"
    | "black"
    | "outline"
    | "disabled"
    | "purple"
    | "submit"
    | "decline";
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
} & HTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  margin,
  size = "small",
  variant = "default",
  loading,
  disabled,
  type,
  ...jsxAttr
}: propsType) => {
  return (
    <button
      type={type}
      disabled={disabled}
      {...jsxAttr}
      className={cn(styles.container, jsxAttr.className, {
        [styles[`margin--${margin}`]]: margin,
        [styles[`${variant}`]]: variant,
        [styles.loading]: loading,
      })}
    >
      {loading && <Icons.loader.LoaderButton className={styles.loaderIcon} />}
      {loading ? "Подождите..." : children}
    </button>
  );
};
