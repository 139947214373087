import { FC } from "react";
import { Controller } from "react-hook-form";
import cn from "classnames";
import styles from "../index.module.css";
import { TextInputPropsType } from "../types";
import { InputWrapper } from "../InputWrapper";
import ReactInputMask from "react-input-mask";
import mergeRefs from "react-merge-refs";
import TextareaAutosize from "react-textarea-autosize";
import get from "lodash/get";

export const InputBase: FC<TextInputPropsType> = ({
  variant = "default",
  textAria = false,
  ...props
}) => {
  const { inputElementRef, control, support, ...jsxAttrs } = props;

  return (
    <InputWrapper
      variant={variant}
      textAria={textAria}
      {...props}
      error={
        props.error ||
        get(
          props.errors as any,
          `${props.name}.message`,
          !props.validInn && props.name === "inn" && "Контрольное число ИНН"
        )
      }
    >
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <>
            {!textAria ? (
              <ReactInputMask
                data-class="input"
                autoComplete="off"
                disabled={variant === "none" && true}
                //@ts-ignore
                maskChar={null}
                mask={props.mask || ""}
                {...jsxAttrs}
                {...field}
                className={cn(styles.Input, props.className, {
                  [styles.Input_error]: props.error,
                  [styles.Input_readonly]: props.readOnly,
                  [styles.Input_large]: variant === "large",
                  [styles.Input_none]: variant === "none",
                })}
                inputRef={mergeRefs([field.ref, props.inputElementRef])}
                style={props.support && { paddingRight: "30px" }}
                placeholder={props.label}
                readOnly={props.readOnly}
                onPaste={props.onPaste}
                onBlur={props.onBlur}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange(e);
                  }
                  field.onChange(e);
                }}
              />
            ) : (
              // @ts-ignore
              <TextareaAutosize
                data-class="input"
                //@ts-ignore
                {...jsxAttrs}
                {...field}
                minRows={jsxAttrs.rows || 3}
                maxRows={jsxAttrs.rows || 3}
                className={cn({
                  [styles.Input_text_aria]: variant === "aria",
                })}
                inputRef={mergeRefs([field.ref, props.inputElementRef])}
                // style={props.support && { paddingRight: "30px" }}
                style={{ height: 61 }}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                //@ts-ignore
                onPaste={props.onPaste}
                //@ts-ignore
                onBlur={props.onBlur}
                onChange={(e: any) => {
                  if (props.onChange) {
                    //@ts-ignore

                    props.onChange(e);
                  }
                  field.onChange(e);
                }}
              />
            )}
          </>
        )}
      />
    </InputWrapper>
  );
};
