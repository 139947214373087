import { mainAxios } from "../index";
import {
  attachingCarPhotoToTheApplicationType,
  attachingDocumentToTheApplicationType, confirmPhotosType,
  downloadPdf,
  downloadPdfWithIdType,
  getCarPhotoFull,
  getCarPhotoPreview,
  getDocumentFull,
  getDocumentPreview, getDocumentPreviewById,
  getInfoFromDocumentType,
  uploadDocumentType,
} from "./types";
import { AxiosResponse } from "axios";

class DocumentsClass {
  user_id: string | null = null;

  constructor() {}

  getDocumentPreview = ({ applicationId, photo_type }: getDocumentPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-preview`
    );

  getDocumentFull = ({ applicationId, photo_type }: getDocumentFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-full`
    );

  getCarPhotoPreview = ({ applicationId, photo_type }: getCarPhotoPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-preview`
    );

  getCarPhotoFull = ({ applicationId, photo_type }: getCarPhotoFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-full`
    );

  getDocumentFullById = ({ applicationId, photo_id }: getDocumentPreviewById) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/photo/${photo_id}/download`
    );

  getDocumentPreviewById = ({ applicationId, photo_id }: getDocumentPreviewById) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/photo/${photo_id}/download:preview`
    );

  getInfoFromDocument = ({
    applicationId,
    photo_type,
  }: getInfoFromDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`
    );

  // confirmPhotos = ({ application_id, ...body }: confirmPhotosType) =>
  //   mainAxios.post(
  //     `/user/${this.user_id}/application/${application_id}/photo-decision`,
  //     body
  //   );

  downloadPdfWithId = ({ applicationId, hash }: downloadPdfWithIdType) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement/${hash}/photo:download`
    );

  downloadPdf = ({ hash }: downloadPdf) => mainAxios.get(`/agr/${hash}`);

  downloadLoanContract = ({ hash }: downloadPdf) =>
    mainAxios.get(`/loan-contract/${hash}`);

  // нужен для сохранения документа на серве, возвращает id, в заявки закидываем именно id
  uploadDocument = (body: uploadDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.post(`/user/${this.user_id}/document`, body);

  attachingDocumentToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingDocumentToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`,
      body
    );

  attachingCarPhotoToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingCarPhotoToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}`,
      body
    );
}

export const documentsApi = new DocumentsClass();
