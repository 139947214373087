import React, { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { SmartForm } from "components/hocs/SmartForm";
import { InputNumber } from "components/ui/Inputs/InputNumber";
import { onPastePhoneNumberHandler } from "helpers/string";
import { Button } from "../../components/Button";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { processLoading } from "store/auxiliary/loadingReducer";

type formType = {
  phone_number: string;
};

export const Phone: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(processLoading.get(authSagaActions.getUser.type));
  const loadingCode = useSelector(
    processLoading.get(authSagaActions.getCode.type)
  );
  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const phone = useWatch({
    control: form.control,
    name: "phone_number",
  });

  const submit = (data: formType) => {
    dispatch(
      authSagaActions.getUser({
        phone: data.phone_number,
        requiredUserRole: "underwriter",
      })
    );
  };

  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit}>
        <InputNumber
          inputMode={"tel"}
          onPaste={onPastePhoneNumberHandler}
          format="+7 ### ### ## ##"
          name="phone_number"
          noNumericString
          label="Номер телефона"
          maxLength={16}
          variant={"large"}
          autoFocus
          className={styles.phone_input}
        />

        <Button
          loading={loading || loadingCode}
          disabled={phone?.length !== 16}
          className={styles.button}
        >
          Продолжить
        </Button>
      </SmartForm>
    </div>
  );
};
