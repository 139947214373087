import {API} from "../../api";
import {AxiosResponse} from "axios";

export const base64Correcter = (base64?: string) => {
  if (!base64) return "";
  const indexOfData = base64.indexOf("data");
  const fileType = base64.slice(indexOfData + 4, base64.indexOf("/"));
  const mimeType = base64.slice(
    base64.indexOf("/") + 1,
    base64.indexOf("base64")
  );
  const data = base64.slice(base64.indexOf("base64") + 6);
  return `data:${fileType}/${mimeType};base64,${data}`;
};

export const getBase64 = (file: any) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const downloadFile = (data: any, name?: string, target?: "_blank") => {
  //NEW VERSION
  const dataArr = data.split(";base64,");
  const contentType = dataArr[0].slice(5);
  const base64Data = dataArr[1];

  try {
    let raw = atob(base64Data);
    let uInt8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    const link = document.createElement("a");
    const blob = new Blob([uInt8Array], { type: contentType });
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);

    link.setAttribute("download", name || "File");
    if (target) {
      link.setAttribute("target", target);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    //OLD VERSION
    // const aTag = document.createElement("a");
    // aTag.setAttribute("href", data);
    // aTag.setAttribute("download", name || "");
    // if (target) {
    //   aTag.setAttribute("target", target);
    // }
    // aTag.click();
    // aTag.remove();
  } catch (e) {
    console.log(e);
  }
};

export const downloadFileLink = (
  data: any,
  name?: string,
  target?: "_blank"
) => {
  try {
    const aTag = document.createElement("a");
    aTag.setAttribute("href", data);
    aTag.setAttribute("download", name || "");
    if (target) {
      aTag.setAttribute("target", target);
    }
    aTag.click();
    aTag.remove();
  } catch (e) {
    console.log(e);
  }
};

export const getFileFromEvent = <T>(e: any): Promise<T> => {
  return new Promise((resolve, reject) => {
    try {
      if (e.target.files) {
        const file = e.target.files![0];
        const reader = new FileReader();
        if (file) {
          reader.readAsDataURL(file);
        }

        const extension = (file.name as string).substr(
          (file.name as string).lastIndexOf(".")
        );

        reader.onloadend = async function () {
          resolve({
            //@ts-ignore
            value: reader.result as string,
            file_name: file.name,
            type: file.type,
            extension,
          });
        };
      }
    } catch (e) {
      reject("Ошибка загрузки файла");
    }
  });
};

/*
    Функиця создает Blob для открытия файла в новой вкладке
 */

export function convertBase64toBlob(content: string, contentType: string) {
  contentType = contentType || "";
  const sliceSize = 512;
  const byteCharacters = window.atob(content.split(",")[1]); //method which converts base64 to binary
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  //statement which creates the blob
  return new Blob(byteArrays, {
    type: contentType,
  });
};

export const getPdfHandler = (id: number) => {
  API.underwriter
      .getElptsExtract({ applicationId: id })
      .then((response: AxiosResponse<any>) => {
        const file = convertBase64toBlob(
            response.data.data.content,
            "application/pdf"
        );
        const a = document.createElement("a");
        a.setAttribute("href", URL.createObjectURL(file));
        a.setAttribute("target", "__blank");
        a.click();
      });
};
