import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { authRoutes } from "../../../../../common_modules/auth/authRoutes";
import { useLocation } from "react-router-dom";

type propsType = {
  variant?: "default" | "white";
} & AllHTMLAttributes<HTMLDivElement>;

export const Title: FC<propsType> = ({
  variant,
  className,
  children,
  ...props
}) => {
  const { pathname } = useLocation();
  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className,
        [styles[`variant_${variant}`]]: variant !== "default",
        [styles.title_code]: pathname === `${authRoutes.code}/restore`,
      })}
    >
      {children}
    </div>
  );
};
