import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaActions } from "../util/sagaActions";
import { applicationsReducer, applicationsReducerSelectors } from "../../store/reducers/applications";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { documentsReducer } from "../../store/reducers/documents";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { userReducerSelectors } from "../../common_modules/auth/selectors";
import { applicationType } from "../../types/Applications/types";
import moment from "moment/moment";

export function* getApplicationsSaga({
  payload,
  type: actionType,
}: PayloadAction) {
  const process = processStateController(actionType);

  // Токен нужен чтобы не пинговать неподписанным запросом в бек после разлогина
  const token: string | null = yield select(userReducerSelectors.getToken);

  if (!token) {
    return;
  }

  try {
    yield process.start();

    const applications: sagaApiType = yield call(
      API.underwriter.getApplications
    );

    yield put(documentsReducer.actions.clear());

    yield put(
      setProcessLoading(sagaActions.underwriter.declineApplication.type, false)
    );
    yield put(
      setProcessLoading(
        sagaActions.underwriter.approveApplication.type,
        false
      )
    );


    yield put(
      applicationsReducer.actions.setApplications({
        applications: applications?.data?.data.sort(
          (a: any, b: any) =>
            // @ts-ignore
            moment(a.created_at) - moment(b.created_at)),
      })
    );

    /*
    Тк запрос на гет заявки по айди более полный, в пинговании заявок мы идем еще и за этим гетом
    Если у нас уже есть этот ответ - пропускаем гет
    */
    // const currentApplication: applicationType = yield select(
    //   applicationsReducerSelectors.getSaveCurrentApplication
    // )

    // id свежей заявки со статусом new
    // const latestAvailableApplication: applicationType = yield select(
    //   applicationsReducerSelectors.getCurrentApplication
    // )

    // гетаем по ид и сетим в стор полный ответ
    // if (!currentApplication && latestAvailableApplication?.id) {
    //   console.log("inside the if");
    //   yield put(sagaActions.underwriter.getApplication({
    //     applicationId: latestAvailableApplication?.id,
    //   }))
    // }

  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.declineApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
