import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../../index";

type initialStateType = {
  disabledCount: number;
  disabledBlock: number | null;
};

const initialState: initialStateType = {
  disabledCount: 0,
  disabledBlock: null,
};

/*
  Дублирует id в checkSwitcherIdReducer, но значение на 1 больше
  Разобраться, зачем, оставить что-то одно
 */
export const VerificationSwitchDisabledReducer = createSlice({
  name: "@verificationSwitchDisabledReducer",
  initialState,
  reducers: {
    setCount: (state, { payload }: PayloadAction<any>) => {
      state.disabledCount = payload;
    },
    setDisabledBlock: (state, { payload }: PayloadAction<any>) => {
      state.disabledBlock = payload;
    },
    clear: () => initialState,
  },
});
export const VerificationSwitchDisabledReducerSelectors = {
  setCount: (state: StateType) =>
    state.VerificationSwitchDisabledReducer.disabledCount,
  setDisabledBlock: (state: StateType) =>
    state.VerificationSwitchDisabledReducer.disabledBlock,
};
