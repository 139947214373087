import {
  arrayOfObjectsInLocalStorage,
  localStorageKeysType,
  localStorageType,
} from "store/localStorage/types";

const rawLc = window.localStorage;

/**
 * @get автоматически делает JSON.parse если ключ
 * занесён в массив arrayOfObjectsInLocalStorage
 * @set автоматически делает JSON.stringify если
 * передаётся объект. Для удаления элемента из lc
 * достатончо передать null
 * @clearAll чистит весь lc
 */
class LSControllerClass {
  constructor() {}

  get<T extends localStorageKeysType>(key: T) {
    const item = rawLc.getItem(key);
    if (arrayOfObjectsInLocalStorage.includes(key) && item) {
      return JSON.parse(item) as localStorageType[T];
    } else {
      return rawLc.getItem(key) as localStorageType[T];
    }
  }
  set<T extends localStorageKeysType>(key: T, value: localStorageType[T]) {
    switch (typeof value) {
      case "string":
      case "number":
      case "boolean":
        rawLc.setItem(key, String(value));
        break;
      case "object":
        rawLc.setItem(key, JSON.stringify(value));
        break;
      default:
        rawLc.removeItem(key);
    }
  }
  clearAll() {
    rawLc.clear();
  }
}

export const lsController = new LSControllerClass();
