import { call, put, select } from "redux-saga/effects";
import { applicationType } from "../../types/Applications/types";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaActions } from "../util/sagaActions";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { applicationsReducer, applicationsReducerSelectors } from "../../store/reducers/applications";
import { checkSwitcherIdReducer } from "../../store/reducers/checkSwitcherId";
import { VerificationSwitchDisabledReducer } from "../../store/reducers/VerificationSwitchDisabled";

// TODO: убрать из названия VideoCall
export function* changeVideoCallApplicationSaga() {
  try {
    put(
      setProcessLoading(sagaActions.underwriter.changeVideoCallApplication.type, true)
    );

    const applications: applicationType[] = yield select(applicationsReducerSelectors.getApplications);

    if (applications.length === 0) return;

    const currentApplication: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    const applicationId = currentApplication.id;

    if (!applicationId) return;

    const filteredApplications = applications.filter(item => item.id !== applicationId);

    const nextApplication = currentApplication.id === applications[0].id
      ? applications[1]
      : applications[0];

    if (applications?.length) {
      yield put(
        applicationsReducer.actions.setApplications({
            applications: filteredApplications
          }
        )
      );
    }

    yield put(sagaActions.underwriter.getApplication({
      applicationId: nextApplication.id
    }));

    // yield put(
    //   applicationsReducer.actions.setCurrentApplication({
    //     ...nextApplication
    //   })
    // );

    yield put(
      VerificationSwitchDisabledReducer.actions.setCount(0)
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.updateApplication.type
    });
  } finally {
    yield put(
      setProcessLoading(sagaActions.underwriter.sendPatchFromScoringSaga.type, false)
    );
  }
}
