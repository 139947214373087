import { PayloadAction } from "@reduxjs/toolkit";
import { getCodeType } from "common_modules/auth/sagas/types";
import { call } from "redux-saga/effects";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { authApi } from "common_modules/auth/api";
import { redirect } from "sagas/util/helpers/redirect";
import { processStateController } from "sagas/util/helpers/processStateController";
import { authRoutes } from "../../authRoutes";

export function* getCodeSaga({
  payload,
  type: actionType,
}: PayloadAction<getCodeType>) {
  const process = processStateController(actionType);
  console.log("getCodeSaga");

  try {
    yield process.start();
    yield call(authApi.code, payload);

    yield redirect(authRoutes.code + `/${payload.smsTemplate}`);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield process.stop();
  }
}