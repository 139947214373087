import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put, select } from "redux-saga/effects";
import moment from "moment/moment";
import { processStateController } from "../util/helpers/processStateController";
import { API } from "../../api";
import { sagaApiType } from "../util/types";
import {
  applicationsReducer,
  applicationsReducerSelectors,
} from "../../store/reducers/applications";
import { sagaActions } from "../util/sagaActions";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { userReducerSelectors } from "../../common_modules/auth/selectors";
import { applicationType } from "../../types/Applications/types";

export function* pingApplicationsSaga({
  payload,
  type: actionType,
}: PayloadAction<{
  delay?: number;
}>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    if (payload.delay) {
      yield delay(payload.delay);
    }

    // Токен нужен чтобы не пинговать неподписанным запросом в бек после разлогина
    const token: string | null = yield select(userReducerSelectors.getToken);

    if (!token) {
      return;
    }

    const saveCurrentApplication: boolean = yield select(
      applicationsReducerSelectors.getSaveCurrentApplication
    );

    const applications: sagaApiType = yield call(
      API.underwriter.getApplications
    );

    const currentApplication: applicationType = yield select(
      applicationsReducerSelectors.getCurrentApplication
    );

    const updatedCurrentApplication = currentApplication && applications.data.data.find(
      (item: applicationType) => item.id === currentApplication.id
    );


    if (applications.data.data.length === 0) {
      yield put(
        applicationsReducer.actions.setApplications({ applications: [] })
      );
      yield put(
        applicationsReducer.actions.setCurrentApplication({})
      );
      return;
    }

    /*
      Эта логика поднимает текущую заявку вперед,
      чтобы при пинге не вылезла более новая заявка по времени поверх той, над которой сейчас работает андер
     */
    if (saveCurrentApplication && updatedCurrentApplication) {
      yield put(
        applicationsReducer.actions.setApplications({
          applications: [updatedCurrentApplication, ...applications?.data?.data
            .filter((item: applicationType) => item.id !== updatedCurrentApplication?.id)
            .sort((a: applicationType, b: applicationType) =>
              Number(moment(a.created_at)) - Number(moment(b.created_at)))],
        })
      );
    } else {
      yield put(
        applicationsReducer.actions.setApplications({
          applications: applications?.data?.data.sort(
            (a: applicationType, b: applicationType) =>
              Number(moment(a.created_at)) - Number(moment(b.created_at)))
        })
      );
      yield put(applicationsReducer.actions.setCurrentApplication({
        ...applications.data.data[0]
      }));
    }

    /*
      Тк запрос на гет заявки по айди более полный, в пинговании заявок мы идем еще и за этим гетом
      Если у нас уже есть этот ответ - пропускаем гет
    */

    // id свежей заявки со статусом new
    // const latestAvailableApplication: applicationType = yield select(
    //   applicationsReducerSelectors.getCurrentApplication
    // )

    // гетаем по ид и сетим в стор полный ответ
    // if (!saveCurrentApplication && latestAvailableApplication?.id) {
    //   yield put(sagaActions.underwriter.getApplication({
    //     applicationId: latestAvailableApplication?.id,
    //   }))
    // }

    if (token) {
      yield put(
        sagaActions.underwriter.pingApplications({
          delay: 300000,
        })
      );
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.declineApplication.type,
    });
  } finally {
    if (!payload.delay) {
      const actionType = authSagaActions.autoAuth.type;
      yield put(setProcessLoading(actionType, false));
    }
  }
}
