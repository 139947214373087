import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../../index";

type initialStateType = {
  id: string | null;
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  user_role: string | null;
  status: string | null;
  phone_number: string | null;
  email: string | null;
  is_has_password: boolean | null;
  created_at: string | null;
  updated_at: string | null;
};

const initialState: initialStateType = {
  id: null,
  name: null,
  surname: null,
  patronymic: null,
  user_role: null,
  status: null,
  phone_number: null,
  email: null,
  is_has_password: null,
  created_at: null,
  updated_at: null,
};

export const UserDataReducer = createSlice({
  name: "@userDataReducerReducer",
  initialState,
  reducers: {
    setName: (state, { payload }: PayloadAction<any>) => {
      state.name = payload;
    },
    setPatronymic: (state, { payload }: PayloadAction<any>) => {
      state.patronymic = payload;
    },
    setSurname: (state, { payload }: PayloadAction<any>) => {
      state.surname = payload;
    },
    setPhone: (state, { payload }: PayloadAction<any>) => {
      state.phone_number = payload;
    },
    clear: () => initialState,
  },
});
export const UserDataReducerSelectors = {
  setName: (state: StateType) => state.UserDataReducer.name,
  setPatronymic: (state: StateType) => state.UserDataReducer.patronymic,
  setSurname: (state: StateType) => state.UserDataReducer.surname,
  setPhone: (state: StateType) => state.UserDataReducer.phone_number,
};
