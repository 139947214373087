export type localStorageType = {
  user_id: string | null;
  merchant_id: string | null;
  token: string | null;
  phone: string | null;
  user_role: string | null;
  transhForm: {
    [key: string]: any;
  } | null;
  applicationMerchant: {
    [key: string]: any;
  } | null;
  didTranshTryRestore: boolean;
  comments: string;
  repledgeData: any;
};
export type localStorageKeysType = keyof localStorageType;
export const arrayOfObjectsInLocalStorage: Array<localStorageKeysType> = [
  "transhForm",
  "applicationMerchant",
  "didTranshTryRestore",
  "repledgeData"
];
