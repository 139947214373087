import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../../index";

type initialStateType = {
  OpenModalNextApplication: boolean;
};

const initialState: initialStateType = {
  OpenModalNextApplication: false,
};

export const modalApplicationReducer = createSlice({
  name: "@modalApplicationReducer",
  initialState,
  reducers: {
    setOpenModal: (state, { payload }: PayloadAction<any>) => {
      state.OpenModalNextApplication = payload;
    },
    clear: (state) => {
      state.OpenModalNextApplication = false;
    },
  },
});
export const modalApplicationReducerSelectors = {
  setOpenModal: (state: StateType) =>
    state.modalApplicationReducer.OpenModalNextApplication,
};
