import { takeEvery } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { declineApplicationSaga } from "./declineApplicationSaga";
import { updateApplicationSaga } from "./updateApplicationSaga";
import { downloadFileSaga } from "./downloadFileSaga";
import { pingApplicationsSaga } from "./pingApplicationsSaga";
import { getApplicationsSaga } from "./getApplicationsSaga";
import { updateApplicationPatchSaga } from "./updateApplicationPatchSaga";
import { clearRedusersSaga } from "../util/helpers/clearRedusersSaga";
import { getUserSaga } from "./getUserSaga";
import { getCurrentApplicationSaga } from "./getCurrentApplicationSaga";
import { approveApplicationSaga } from "./approveApplicationSaga";
import { uploadApplicationDocumentSaga } from "./uploadApplicationDocumentSaga";
import { sendPatchFromScoringSaga } from "./sendPatchFromScoringSaga";
import { changeVideoCallApplicationSaga } from "./changeVideoCallApplicationSaga";
import { LogoutSaga } from "./LogoutSaga";
import { sendPatchFromCreditworthinessSaga } from "./sendPatchFromCreditworthinessSaga";
import { approveApplicationSuperUnderwriterSaga } from "./approveApplicationSuperUnderwriterSaga";
import { changeApplicationSaga } from "./changeApplicationSaga";

export function* underwriterSaga() {
  yield takeEvery(
    sagaActions.underwriter.declineApplication,
    declineApplicationSaga
  );
  yield takeEvery(
    sagaActions.underwriter.approveApplication,
    approveApplicationSaga
  );
  yield takeEvery(
    sagaActions.underwriter.approveApplicationSuperUnderwriter,
    approveApplicationSuperUnderwriterSaga
  );
  yield takeEvery(
    sagaActions.underwriter.updateApplication,
    updateApplicationSaga
  );
  yield takeEvery(
    sagaActions.underwriter.updateApplicationPatch,
    updateApplicationPatchSaga
  );
  yield takeEvery(sagaActions.underwriter.downloadFile, downloadFileSaga);

  yield takeEvery(
    sagaActions.underwriter.pingApplications,
    pingApplicationsSaga
  );

  yield takeEvery(sagaActions.underwriter.getApplications, getApplicationsSaga);

  yield takeEvery(sagaActions.underwriter.getUserSaga, getUserSaga);

  yield takeEvery(
    sagaActions.underwriter.getApplication,
    getCurrentApplicationSaga
  );

  yield takeEvery(sagaActions.underwriter.clearRedusersSaga, clearRedusersSaga);

  yield takeEvery(
    sagaActions.underwriter.uploadApplicationDocumentSaga,
    uploadApplicationDocumentSaga
  );
  yield takeEvery(
    sagaActions.underwriter.sendPatchFromScoringSaga,
    sendPatchFromScoringSaga
  );
  yield takeEvery(
    sagaActions.underwriter.sendPatchFromCreditworthinessSaga,
    sendPatchFromCreditworthinessSaga
  );
  yield takeEvery(
    sagaActions.underwriter.changeVideoCallApplication,
    changeVideoCallApplicationSaga
  );
  // yield takeEvery(
  //   sagaActions.underwriter.changeApplication,
  //   changeApplicationSaga
  // );
  yield takeEvery(sagaActions.underwriter.logout, LogoutSaga);
}
