import * as axios from "axios";
import { applicationApi } from "./application";
import { userSaleApi } from "./user_sale";
import { servicesApi } from "./services";
import {documentsApi} from "./documents";

export const mainAxios = axios.default.create({
  baseURL: process.env.REACT_APP_UNDERWRITER_URL,
  timeout: 30000,
});

export const mainApi = {
  application: applicationApi,
  user_sale: userSaleApi,
  services: servicesApi,
  documents: documentsApi,

};
